<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <p v-html="currentEntity()"></p>
        <!-- resourceId: {{ resourceId }}<br />
        dbRowData:
        <pre>{{ dbRowData }}</pre> -->
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-row>
              <div
                :class="`form-group col-md-${
                  fattutto_configurable_type_clean !== fattutto_ct_various_acc
                    ? '6'
                    : '12'
                }`"
              >
                <base-checkbox
                  :name="beForm.fattutto.active.label"
                  vid="fattutto_active"
                  groupLabel=""
                  :label="beForm.fattutto.active.label"
                  v-model="form.fattutto.active"
                  :chk_val="true"
                  :unchk_val="false"
                />
              </div>
              <template
                v-if="
                  fattutto_configurable_type_clean !== fattutto_ct_various_acc
                "
              >
                <div class="form-group col-md-6">
                  <base-input
                    :name="beForm.fattutto.code.label"
                    vid="fattutto_code"
                    :label="beForm.fattutto.code.label"
                    v-model="form.fattutto.code"
                    placeholder="Inserisci un codice"
                    textcase="upper"
                    :rules="getCustomRules('fattutto_code')"
                  />
                </div>
              </template>
            </b-row>
            <template
              v-if="fattutto_configurable_type_clean === fattutto_ct_sector"
            >
              <b-row>
                <div class="form-group col-md-6">
                  <base-select
                    :name="beForm.fattutto.partitario.label"
                    vid="fattutto_partitario"
                    :label="beForm.fattutto.partitario.label"
                    v-model="form.fattutto.partitario"
                    :options="[
                      { value: 0, text: 'Compagnia' },
                      { value: 1, text: 'Produttore' },
                      { value: 2, text: 'Codice' },
                      { value: 3, text: 'Nessuno' },
                    ]"
                    :rules="{ required: true }"
                  />
                </div>
                <div
                  class="form-group col-md-6"
                  v-if="form.fattutto.partitario === 2"
                >
                  <base-input
                    :name="beForm.fattutto.partitario_code.label"
                    vid="fattutto_partitario_code"
                    :label="beForm.fattutto.partitario_code.label"
                    v-model="form.fattutto.partitario_code"
                    placeholder="Inserisci un codice di massimo 5 caratteri"
                    maxlength="5"
                    textcase="upper"
                  />
                </div>
              </b-row>
              <b-row>
                <div class="form-group col-md-6">
                  <base-checkbox
                    :name="beForm.fattutto.negative_enabled.label"
                    vid="fattutto_negative_enabled"
                    groupLabel=""
                    :label="beForm.fattutto.negative_enabled.label"
                    v-model="form.fattutto.negative_enabled"
                    :chk_val="true"
                    :unchk_val="false"
                  />
                </div>
                <div
                  class="form-group col-md-6"
                  v-if="form.fattutto.negative_enabled"
                >
                  <base-input
                    :name="beForm.fattutto.negative_code.label"
                    vid="fattutto_negative_code"
                    :label="beForm.fattutto.negative_code.label"
                    v-model="form.fattutto.negative_code"
                    placeholder="Inserisci un codice di massimo 15 caratteri"
                    textcase="upper"
                    maxlength="5"
                    :rules="getCustomRules('negative_code')"
                  />
                </div>
              </b-row>
            </template>
            <b-row>
              <b-col align="right">
                <b-button
                  :disabled="invalid"
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
// import FormMixin from "@/mixins/FormMixin";
// import ShortcutMixin from "@/mixins/ShortcutMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseSelect from "@/components/form/BaseSelect";
import { setFormValues } from "@/utils/forms";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  name: "Edit",
  // mixins: [FormMixin, ShortcutMixin],
  components: {
    BaseIcon,
    BaseCheckbox,
    BaseInput,
    BaseSelect,
  },
  data() {
    return {
      isLoading: false,
      repository: "fattutto",
      resource: "configuration",
      entity_ref_id: null,
      fattutto_ct_sector: "sector",
      fattutto_ct_various_acc: "variousAccounting",
      fattutto_id: null,
      fattutto_configurable_type: "",
      fattutto_configurable_type_clean: "",
      beForm: {},
      form: {
        fattutto: {
          code: "",
          active: true,
          partitario: null,
          partitario_code: null,
          // registry_transfer: true,
          negative_enabled: false,
          negative_code: "",
        },
      },
    };
  },
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    getCustomRules(field) {
      let rules = {};
      if (field === "fattutto_code") {
        if (this.form.fattutto.active) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      } else if (field === "negative_code") {
        if (this.form.fattutto.negative_enabled) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      }
      return rules;
    },
    currentEntity() {
      const entity = this?.dbRowData;
      if (!entity) return "Entità non trovata";
      this.fattutto_id = entity.id;
      this.fattutto_configurable_type = entity.configurable_type;
      this.entity_ref_id = entity.configurable_id;
      this.initBeForm(entity);
      return this.renderEntityCode(entity);
    },
    initBeForm(item) {
      this.beForm.fattutto = {};
      const entity = this.renderConfigurableTypeLabel(item.configurable_type);
      const g = ["Produttore", "Comparto contabile"].includes(entity)
        ? "m"
        : "f";
      this.beForm["fattutto"].active = {
        label: `${entity} attiv${
          g === "m" ? "o" : "a"
        } all'esportazione per contabilità fiscale`,
        type: "select",
        value: Boolean(item.active),
      };
      this.beForm["fattutto"].code = {
        label: `Codice ${entity} per flusso esportazione contabilità fiscale`,
        type: "text",
        value: item?.code ?? null,
      };
      this.beForm["fattutto"].partitario = {
        label: `Partitario`,
        type: "select",
        value: item?.ledger_type,
      };
      this.beForm["fattutto"].partitario_code = {
        label: `Codice Partitario`,
        type: "text",
        value: item?.ledger_code ?? null,
      };
      this.beForm["fattutto"].negative_enabled = {
        label: `Codice per flusso negativo`,
        type: "select",
        value: item?.negative_code ? true : false,
      };
      this.beForm["fattutto"].negative_code = {
        label: `Codice Comparto per flusso negativo`,
        type: "text",
        value: item?.negative_code ?? null,
      };
      /* */
      this.form["fattutto"] = setFormValues(
        [
          "active",
          "code",
          "partitario",
          "partitario_code",
          "negative_enabled",
          "negative_code",
        ],
        this.beForm["fattutto"]
      );
      /* */
    },
    renderConfigurableTypeLabel(entity) {
      let ret = "";
      switch (entity) {
        case "App\\Models\\Insurer":
          ret = "Compagnia";
          break;
        case "App\\Models\\Broker":
          ret = "Produttore";
          break;
        case "App\\Models\\VariousAccounting":
          ret = "Causale contabile movimenti vari";
          break;
        case "App\\Models\\Sector":
          ret = "Comparto contabile";
          break;
        default:
          console.log("No entity matched!");
          break;
      }
      return ret;
    },
    renderEntityCode(item) {
      let ret = "";
      switch (item.configurable_type) {
        case "App\\Models\\Insurer":
          this.fattutto_configurable_type_clean = "insurer";
          ret = `Compagnia: <strong>${item.configurable.code} - ${item.configurable.title}</strong>`;
          break;
        case "App\\Models\\Broker":
          this.fattutto_configurable_type_clean = "broker";
          ret = `Produttore: <strong>${item.configurable.attributables.BUID} - ${item.configurable.attributables.SURN} ${item.configurable.attributables.NAME}</strong>`;
          break;
        case "App\\Models\\VariousAccounting":
          this.fattutto_configurable_type_clean = "variousAccounting";
          ret = `Causale contabile movimenti vari: <strong>${item.configurable.code} - ${item.configurable.title}</strong>`;
          break;
        case "App\\Models\\Sector":
          this.fattutto_configurable_type_clean = "sector";
          ret = `Comparto contabile: <strong>${item.configurable.code} - ${item.configurable.title}</strong>`;
          break;
        default:
          console.log("No configurable_type matched!");
          break;
      }
      return ret;
    },
    onSubmit() {
      this.isLoading = true;
      if (!this.fattutto_id) {
        this.$showSnackbar({
          preset: "error",
          text: `ID Fattutto mancante.`,
        });
        this.isLoading = false;
        return;
      }
      /* SAVE HERE FATTUTTO CONFIG with a new API call */
      const Repo = RepositoryFactory.get(this.rep);
      const payload = {
        configurable_id: this.entity_ref_id,
        configurable_type: this.fattutto_configurable_type_clean,
        active: this.form.fattutto.active,
        ledger_type: 3, // no ledger_type
      };
      if (
        this.fattutto_configurable_type_clean !== this.fattutto_ct_various_acc
      ) {
        payload["code"] = this.form.fattutto.code;
      }
      if (this.fattutto_configurable_type_clean === this.fattutto_ct_sector) {
        // Override default ledger_type
        payload["ledger_type"] = this.form.fattutto.partitario;
        if (this.form.fattutto.partitario === 2) {
          payload["ledger_code"] = this.form.fattutto.partitario_code;
        }
        payload["negative_code"] = this.form.fattutto.negative_code;
      }
      Repo.update_configuration(this.fattutto_id, payload)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Entità modificata`,
          });
          this.$emit("updateEnd");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
