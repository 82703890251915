<template>
  <div class="mt-2">
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div style="position: relative">
        <b-button
          type="button"
          variant="lisaweb"
          size="sm"
          @click="onSendOrRetry()"
          title="Refresh"
          style="position: absolute; top: -38px; left: 0"
          :disabled="!checkedIds.length"
        >
          <base-icon name="send" width="14" />
          <b-icon icon="plus" aria-hidden="true"></b-icon>
          <b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
          Invia selezionati
        </b-button>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono record da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <!-- -->
          <!-- <template
            v-slot:head(check)="data"
            v-if="hasChecks && !isCheckExclusive && items.length"
          >
            <base-checkbox
              v-model="checkAll"
              name="all"
              class="check"
              groupLabel=""
              :label="data.label"
              @input="
                checkedAll();
                $emit('interaction', items, checkedIds);
              "
            >
            </base-checkbox>
          </template>
          <template #cell(check)="row" v-if="hasChecks">
            <span v-if="checkIsChecked">{{ checkIsChecked(row.item) }}</span>
            <base-checkbox
              name="check"
              class="check"
              v-model="row.item.check"
              @change="checkedEl($event, row.index, row.item)"
              @input="
                $emit('interaction', [row.item], checkedIds);
                onSelectRow($event, row.index, row.item);
              "
            />
          </template> -->
          <template
            v-slot:head(check)="data"
            v-if="hasChecks && !isCheckExclusive && items.length"
          >
            <base-checkbox
              v-model="checkAll"
              name="all"
              class="check"
              groupLabel=""
              :label="data.label"
              @change="
                // $emit('interaction');
                checkedAll();
                $emit('interaction', checkedIds);
              "
            >
            </base-checkbox>
          </template>
          <template #cell(check)="row" v-if="hasChecks">
            <span style="display: none !important" v-if="checkIsChecked">{{
              checkIsChecked(row.item)
            }}</span>
            <base-checkbox
              name="check"
              class="check"
              v-model="row.item.check"
              @change="checkedEl($event, row.index, row.item)"
              @input="
                // $emit('interaction');
                onSelectRow($event, row.index, row.item);
                $emit('interaction', checkedIds);
              "
              :hidden="
                Object.prototype.hasOwnProperty.call(
                  row.item.fattutto_transmission,
                  'transmission_status'
                ) &&
                [0, 1, 2, 5, 6].includes(
                  row.item.fattutto_transmission.transmission_status
                )
              "
            />
          </template>
          <!-- -->
          <template #cell(actions)="row">
            <div class="action-buttons">
              <!-- <b-button
                size="sm"
                variant="lisaweb"
                class="mt-1 mr-1"
                title="Crea"
                @click="createTrasmission(row.item)"
                :disabled="
                  Object.prototype.hasOwnProperty.call(
                    row.item.fattutto_transmission,
                    'transmission_status'
                  )
                "
              >
                <b-icon icon="plus-circle"></b-icon>
              </b-button> -->
              <b-button
                size="sm"
                variant="lisaweb"
                class="mt-1 mr-1"
                title="Trasmetti"
                @click="sendOrRetryTrasmission(row.item, 'send')"
                :disabled="
                  Object.prototype.hasOwnProperty.call(
                    row.item.fattutto_transmission,
                    'transmission_status'
                  )
                "
              >
                <base-icon name="send" width="14" />
              </b-button>

              <b-button
                size="sm"
                variant="lisaweb"
                class="mt-1 mr-1"
                title="Riprova trasmissione"
                @click="sendOrRetryTrasmission(row.item, 'retry')"
                :disabled="
                  !Object.prototype.hasOwnProperty.call(
                    row.item.fattutto_transmission,
                    'transmission_status'
                  ) ||
                  ![3, 4].includes(
                    row.item.fattutto_transmission.transmission_status
                  )
                "
              >
                <b-icon icon="arrow-clockwise"></b-icon>
              </b-button>
            </div>
          </template>
          <template v-slot:[fattuttoPerc]="row">
            <template
              v-if="
                Object.prototype.hasOwnProperty.call(
                  row.item.fattutto_transmission,
                  'progress_percentage'
                ) &&
                ![0, 1, 5, 6].includes(
                  row.item.fattutto_transmission.transmission_status
                )
              "
            >
              <div class="progress-wrapper">
                <b-progress
                  v-if="
                    (nestedValue = getNestedValue(row.item, fattutto_perc)) !=
                    null
                  "
                  :value="nestedValue"
                  :variant="getProgressBarVariant(nestedValue)"
                  class="custom-progress-bar"
                  max="100"
                ></b-progress>
                <span class="progress-value">{{
                  nestedValue != null ? `${nestedValue}%` : "-"
                }}</span>
              </div>
            </template>
            <span v-else>-</span>
          </template>
          <template v-slot:[fattuttoRecords]="row">
            <template
              v-if="
                Object.prototype.hasOwnProperty.call(
                  row.item.fattutto_transmission,
                  'record'
                ) &&
                ![0, 1, 5, 6].includes(
                  row.item.fattutto_transmission.transmission_status
                )
              "
            >
              <span class="d-flex align-items-center" style="gap: 2px">
                <span>{{
                  getNestedValue(row.item, fattutto_records_sent)
                }}</span>
                <span class="separator">/</span>
                <span style="color: red">{{
                  getNestedValue(row.item, fattutto_records_error)
                }}</span>
                <span class="separator">/</span>
                <span>{{ getNestedValue(row.item, fattutto_records) }}</span>
              </span>
            </template>
            <span v-else>-</span>
          </template>
          <template v-slot:[fattuttoErrors]="row">
            <template
              v-if="
                Object.prototype.hasOwnProperty.call(
                  row.item.fattutto_transmission,
                  'errors'
                ) &&
                ![0, 1, 5, 6].includes(
                  row.item.fattutto_transmission.transmission_status
                )
              "
            >
              <template
                v-if="
                  (countError = getNestedValue(
                    row.item,
                    fattutto_errors
                  ).length) != null
                "
              >
                <div
                  :class="`info-box${!countError ? ' disabled' : ''}`"
                  v-b-tooltip="{
                    title: `<ul>${renderErrors(
                      row.item.fattutto_transmission.errors
                    )}</ul>`,
                    placement: 'left',
                    html: true,
                  }"
                  @click="
                    customOpenErrorsModal(row.item, row.index, $event.target)
                  "
                >
                  {{ countError }}
                </div>
              </template>
            </template>
            <span v-else>-</span>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <!-- -->
      <b-modal
        :dialog-class="customModalClass"
        :id="infomodalName"
        @show="onModalShow"
        @hidden="onModalHidden"
        :title="custoModalTitle"
        :size="custoModalSize"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  :dbRowData="dbRowData"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
      <!-- -->
    </div>
  </div>
</template>

<script>
import BaseInputPagination from "@/components/form/BaseInputPagination";
import BaseIcon from "@/components/BaseIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import template from "./template";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import Errors from "@/components/fattutto/transmission/details/Errors.vue";

export default {
  name: "FattuttoTransmissionsTableRef",
  extends: template,
  data() {
    return {
      checkedIds: [],
      fattutto_perc: "fattutto_transmission.progress_percentage",
      fattutto_records: "fattutto_transmission.record",
      fattutto_records_error: "fattutto_transmission.record_error",
      fattutto_records_sent: "fattutto_transmission.record_sent",
      fattutto_errors: "fattutto_transmission.errors",
      customModalClass: "modal-fattutto-errors",
      counterSelezionate: 0,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      custoModalTitle: "Dettaglio errori",
      custoModalSize: "lg",
      modalTitleDefault: "Dettaglio errori",
      modalTitleGroup: "Dettaglio errori",
      modalSizeGroup: "lg",
      modalSizeDefault: "lg",
      tabs: [{ name: "Errors", text: "Errori" }],
      selected: "Errors", // dynamically load selected component in tabs
      modalResourceId: null, // prop for the dynamic components
      tabIndex: 0, // remember last visited tab: set active tab when modal gets opened
      dbRowData: null, // used to pass the actual record to tabs
    };
  },
  props: {
    hasChecksPaginated: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseIcon,
    BaseCheckbox,
    BaseInputPagination,
    Errors,
  },
  methods: {
    onModalShow() {
      if (this.tabIndex < this.tabs.length)
        this.setTab(this.tabs[this.tabIndex], this.tabIndex);
    },
    onModalHidden() {
      this.setTab({ name: "Errors", text: "Errori" }, 0);
      this.customModalClass = "modal-fattutto-errors";
      this.custoModalTitle = this.modalTitleDefault;
      this.custoModalSize = this.modalSizeDefault;
    },
    customOpenErrorsModal(item, index, target) {
      this.tabIndex = 0; // TabIndex ID delle Note
      this.openModal(item, index, target);
    },
    onSendOrRetry() {
      this.selectedRows = [];
      this.checkedIds = [];
      this.checkAll = false;
      this.$emit("onSendOrRetry");
      /* emit these
       * this.hasUserInteraction = false;
       * this.$refs[this.tableRef].fetch(this.current_config);
       */
    },
    changeCheckAll(condition, event) {
      console.log("event", event);
      this.checkAll = condition;
    },
    checkIsChecked(item) {
      // const evl = this.checkedIds.indexOf(item.consolidated_at) >= 0;
      const evl = this.checkedIds.some(
        (el) => el.consolidated_at === item.consolidated_at
      );
      item.check = evl;
      return evl;
    },
    checkedEl(event, index, item) {
      console.log("clicked :", index);
      if (!this.hasChecksPaginated) return;
      // if (event && this.checkedIds.indexOf(item.consolidated_at) === -1) {
      const i = this.checkedIds.findIndex(
        (el) => el.consolidated_at === item.consolidated_at
      );
      if (event && i === -1) {
        // this.checkedIds.push(item.consolidated_at);
        this.checkedIds.push(item);
      } else {
        // const i = this.checkedIds.indexOf(item.consolidated_at);
        // const i = this.checkedIds.findIndex(
        //   (el) => el.consolidated_at === item.consolidated_at
        // );
        if (i > -1) {
          this.checkedIds.splice(i, 1);
        }
      }
      this.$emit("singleCheck", this.checkedIds);
    },
    checkedAll() {
      this.items.forEach((item) => {
        this.checkedEl(true, null, item);
      });
    },
    renderErrors(errors) {
      return errors.map((el) => `<li>${el}</li>`).join("");
    },
    sendOrRetryTrasmission(item, action) {
      const payload = {
        date: item.consolidated_at,
      };
      this.isLoading = true;
      const Repo = RepositoryFactory.get("fattutto");
      (action === "send"
        ? Repo.send_transmission(payload)
        : Repo.retry_transmission(item.fattutto_transmission.id)
      )
        .then(() => {
          this.$emit("transmissionActionsEnd");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: ${
              action === "send"
                ? "Trasmissione Inviata"
                : "Nuovo tentativo di trasmissione inviato"
            }`,
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
      // .finally(() => {
      //   this.isLoading = false;
      // });
    },
    // createTrasmission(item) {
    //   this.isLoading = true;
    //   const Repo = RepositoryFactory.get("fattutto");
    //   const payload = {
    //     date: item.consolidated_at,
    //   };
    //   Repo.create_transmission(payload)
    //     .then(() => {
    //       this.$emit("transmissionActionsEnd");
    //       this.$showSnackbar({
    //         preset: "success",
    //         text: `Azione Completata: Trasmissione Creata`,
    //       });
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({
    //         preset: "error",
    //         text: `${errMsg}`,
    //       });
    //     });
    //   // .finally(() => {
    //   //   this.isLoading = false;
    //   // });
    // },
    getNestedValue(obj, path) {
      return path
        .split(".")
        .reduce((acc, key) => (acc ? acc[key] : undefined), obj);
    },
    getProgressBarVariant(value) {
      let ret = "";
      switch (true) {
        case value < 25:
          ret = "danger";
          break;
        case value >= 25 && value < 100:
          ret = "warning";
          break;
        case value == 100:
          ret = "success";
          break;
        default:
          ret = "info";
          break;
      }
      return ret;
    },
  },
  computed: {
    fattuttoPerc() {
      return `cell(${this.fattutto_perc})`;
    },
    fattuttoRecords() {
      return `cell(${this.fattutto_records})`;
    },
    fattuttoErrors() {
      return `cell(${this.fattutto_errors})`;
    },
  },
  // beforeMount() {
  //   this.actions.push("infomodal");
  // },
};
</script>

<style lang="css" scoped>
:deep(.progress-wrapper) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90px;
}

:deep(.progress) {
  width: 50px;
  height: 5px;
  border: 0;
  border-radius: 100vmax;
}

:deep(td.check) {
  padding: 0;
}

:deep(td.check span.check) {
  display: flex;
  justify-content: center;
  translate: 0 2px;
}

:deep(th.check .form-group) {
  margin: 0;
}

:deep(td.check .form-group) {
  margin: 0;
}

:deep(th.check .custom-checkbox) {
  padding-left: 30px;
}

:deep(td.check .custom-checkbox) {
  padding-left: 30px;
}

:deep(td.check .custom-checkbox + .invalid-feedback) {
  margin: 0;
}
</style>
