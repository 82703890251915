var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.canVerb('fattutto', 'config'))?_c('div',{staticClass:"fattutto-index-transmissions mx-1"},[_c('b-overlay',{attrs:{"center":"","rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center mt-5"},[_c('b-icon',{attrs:{"icon":"stopwatch","font-scale":"3","animation":"cylon"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Operazione in corso...")])],1)]},proxy:true}],null,false,1492235527)},[_c('b-row',[_c('b-col',{attrs:{"align":"left"}},[_c('b-button-group',{directives:[{name:"b-toggle",rawName:"v-b-toggle:collapse-1",arg:"collapse-1"}],staticClass:"my-2 filter-button-group",attrs:{"title":_vm.getDefaultFilterMessage()}},[_c('span',{staticClass:"when-open"},[_c('b-icon',{attrs:{"icon":"funnel","font-scale":"1.5"}})],1),_vm._v(" "),_c('span',{staticClass:"when-closed"},[_c('b-icon',{attrs:{"icon":"funnel","font-scale":"1.5"}})],1),_vm._v("\n          Filtra\n        ")])],1)],1),_vm._v(" "),_c('b-collapse',{attrs:{"visible":"","id":"collapse-1"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSearch(_vm.filterName)}}},[_c('b-card',{staticClass:"filter"},[_c('b-row',[_c('div',{staticClass:"col-md-3"},[_c('base-select',{attrs:{"name":"entity_type","vid":"entity_type","label":"Entità","options":[
                  {
                    value: 'variousAccounting',
                    text: 'Causale contabile movimenti vari',
                  },
                  { value: 'insurer', text: 'Compagnia' },
                  { value: 'sector', text: 'Comparto contabile' },
                  { value: 'broker', text: 'Produttore' },
                ]},model:{value:(_vm.filter.configurable_type),callback:function ($$v) {_vm.$set(_vm.filter, "configurable_type", $$v)},expression:"filter.configurable_type"}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-3"},[_c('base-input',{attrs:{"name":"fattutto_code","vid":"fattutto_code","label":"Codice Fattutto","placeholder":"Inserisci un codice"},model:{value:(_vm.filter.code),callback:function ($$v) {_vm.$set(_vm.filter, "code", $$v)},expression:"filter.code"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-3 align-self-end"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle:collapse-1",arg:"collapse-1"}],attrs:{"type":"submit","variant":"lisaweb","size":"sm"}},[_vm._v("Cerca")]),_vm._v(" "),_c('b-button',{staticClass:"btn-reset",attrs:{"type":"button","variant":"lisaweb","size":"sm"},on:{"click":function($event){return _vm.onClearFilter(_vm.filterName)}}},[_vm._v("Reset")])],1)])],1)],1)],1),_vm._v(" "),_c('fattutto-configuration-table',{ref:_vm.tableRef,attrs:{"fields":_vm.fields,"items":_vm.items,"repository":_vm.repository,"resource":_vm.resource,"filterName":_vm.filterName},on:{"forcedFetch":_vm.forcedFetch}})],1)],1):_c('div',{staticClass:"mt-3"},[_c('b-icon',{attrs:{"icon":"info-circle","scale":"1.00"}}),_vm._v("\n  Non hai i permessi\n")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }