<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <!-- resourceId: {{ resourceId }}<br /> -->
        <!-- dbRowData: <pre>{{ dbRowData }}</pre> -->
        <div
          v-if="Object.keys(dbRowData).length"
          class="mb-4"
          v-html="rederInfo"
        ></div>
        <div class="errors-wrapper" v-if="errors" v-html="errors"></div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import moment from "moment";
moment.locale("it");

export default {
  name: "Errori",
  components: {
    BaseIcon,
  },
  data() {
    return {
      errors: null,
      isLoading: true,
      repository: "fattutto",
      resource: "transmissions",
    };
  },
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {},
  methods: {
    onSearch() {
      const Repo = RepositoryFactory.get(this.rep);
      const id = this?.dbRowData?.fattutto_transmission?.id;
      if (!id) {
        this.$showSnackbar({
          preset: "error",
          text: `Siamo spiacenti, c'è stato un errore. Ti preghiamo di riprovare più tardi.`,
        });
        return;
      }
      Repo.show_transmission(id)
        .then((response) => {
          console.log("response", response);
          const response_errors = response?.data?.data?.errors;
          if (!response_errors || !response_errors?.length) {
            this.errors = `
              <div class="error-item">
                <p><i>Nessun errore da visualizzare.</i></p>
              </div>
            `;
            return;
          }
          this.errors = response_errors
            .map((el) => {
              return `
              <div class="error-item">
                <div class="custom-grid-wrapper">
                  <span data-book-entry-id="${el.book_entry_id}">Registrazione</span><strong>${el.book_entry_title}</strong>
                  <span>Errore</span><strong>${el.error}</strong>
                </div>
              </div>
            `;
            })
            .join("");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    rederInfo() {
      return `
        <div class="custom-grid-wrapper">
          <span>Data consolidata</span><strong>${
            this?.dbRowData?.consolidated_at
              ? moment(this.dbRowData.consolidated_at).format("DD/MM/YYYY")
              : "-"
          }</strong>
        </div>
      `;
    },
    rep() {
      return this.repository;
    },
  },
  mounted() {
    this.onSearch();
  },
};
</script>
<style lang="scss">
.error-item {
  border-bottom: 1px solid #dee2e6;
  padding: 0 0 10px 0;
  margin-block: 10px;
}
.error-item p {
  margin: 0;
}
div .error-item:last-of-type {
  border-bottom: 0;
}
</style>
