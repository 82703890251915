<template>
  <div
    class="fattutto-index-transmissions mx-1"
    v-if="canVerb('fattutto', 'send')"
  >
    <b-overlay center rounded="sm">
      <b-row class="mt-2">
        <!-- <b-col align="left">
          <b-button-group
            v-b-toggle:collapse-1
            class="my-2 filter-button-group"
            :title="getDefaultFilterMessage()"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Filtra
          </b-button-group>
        </b-col>
        <b-col align="right">
          <b-button
            :id="`popover-create`"
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="onAdd"
            title="Crea"
            v-if="canVerb(resource, 'store')"
            ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
            Crea</b-button
          >
        </b-col> -->
        <b-col align="right">
          <b-button
            type="button"
            variant="lisaweb"
            size="sm"
            @click="onRefreshContent()"
            title="Refresh"
            ><b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon
            >Ricarica</b-button
          >
        </b-col>
      </b-row>
      <!-- <b-row class="mt-2">
        <b-col align="left">
          ID:
          <pre>{{ table_checked_ids }}</pre>
        </b-col>
      </b-row> -->
      <!-- <b-collapse visible id="collapse-1">
        <b-form @submit.prevent="onSearch(filterName)">
          <b-card class="filter">
            <b-row>
              <div class="col-md-3">
                <base-select
                  name="bank"
                  label="Banca"
                  :options="bank_accounts"
                  v-model="filter.byBankAccount.id"
                />
              </div>
              <div class="col-md-3">
                <base-month-picker
                  name="setup_at"
                  label="Data istanziamento SDD"
                  v-model="filter.byAttribute.setup_at"
                />
              </div>
              <div class="form-group col-md-3 align-self-end">
                <b-button
                  type="submit"
                  variant="lisaweb"
                  size="sm"
                  v-b-toggle:collapse-1
                  >Cerca</b-button
                >
                <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
                  >Reset</b-button
                >
              </div>
            </b-row>
          </b-card>
        </b-form>
      </b-collapse> -->

      <fattutto-transmissions-table
        :fields="fields"
        :items="items"
        :repository="repository"
        :resource="resource"
        :ref="tableRef"
        hasChecks
        :hasChecksPaginated="true"
        :onPagination="{
          action: 'confirm',
          checkCondition: hasChanges,
          title: 'Conferma salvataggio',
          message: 'Stai per cambiare pagina. Salvare le modifiche?',
          yesCallback: saveOnPageChange,
          noCallback: onCancelConfirm,
          yesLabel: 'SALVA',
          yesVariant: 'lisaweb',
          noLabel: 'ANNULLA',
        }"
        @interaction="onInteraction"
        @singleCheck="onInteraction"
        @onSendOrRetry="sendOrRetry"
        @transmissionActionsEnd="transmissionActionsEnd"
      ></fattutto-transmissions-table>

      <!-- <div>
        <multi-select-option-modal
          title="Creazione nuovo flusso"
          description=""
          @create="create"
        />
      </div> -->

      <template #overlay>
        <div class="text-center mt-5">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
// import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import FattuttoTransmissionsTable from "@/components/tables/FattuttoTransmissions.vue";
import FormMixin from "@/mixins/FormMixin";
// import BaseSelect from "@/components/form/BaseSelect";
// import { mapGetters, mapActions } from "vuex";
import { toLocaleDate } from "@/utils/dates";
import moment from "moment";
moment.locale("it");
import { canVerb } from "@/utils/permissions";

export default {
  mixins: [CustomFiltersMixin, FormMixin],
  data() {
    return {
      hasUserInteraction: false, // se l'utente seleziona/deseleziona checkbox e/o select
      isLoading: false,
      repository: "fattutto",
      resource: "config",
      tableRef: "FattuttoSendTableRef",
      current_config: {},
      // filterName: "filterDirectDebits",
      // filter: this.initFilter(),
      table_checked_ids: [],
      fields: [
        {
          key: "check",
          label: "",
          sortable: false,
          thClass: "check",
          thStyle: {
            width: "10px",
          },
          class: "check",
        },
        {
          key: "actions",
          label: "Azioni",
          thClass: "align-middle",
          thStyle: {
            width: "40px",
            //width: `${20 * this.actions.length}px`,
          },
          tdClass: "bg-light actions",
        },
        {
          key: "consolidated_at",
          label: this.getDictionary("consolidated_at", "fattutto"),
          formatter: (value) => toLocaleDate(value, "DD/MM/YYYY"),
        },
        // {
        //   key: "fattutto_transmission.id",
        //   label: this.getDictionary("fattutto_transmission_id", "fattutto"),
        //   formatter: (value) => value ?? "-",
        // },
        {
          key: "fattutto_transmission.transmission_status",
          label: this.getDictionary("transmission_status", "fattutto"),
          formatter: (value) =>
            value != null ? this.transmission_status[value] : "-",
        },
        {
          key: "fattutto_transmission.created_at",
          label: this.getDictionary("created_at", "fattutto"),
          formatter: (value) =>
            value ? toLocaleDate(value, "DD/MM/YYYY HH:mm:ss") : "-",
        },
        {
          key: "fattutto_transmission.updated_at",
          label: this.getDictionary("updated_at", "fattutto"),
          formatter: (value) =>
            value ? toLocaleDate(value, "DD/MM/YYYY HH:mm:ss") : "-",
        },
        {
          key: "fattutto_transmission.progress_percentage",
          label: this.getDictionary("progress_percentage", "fattutto"),
          formatter: (value) => `${value ? `${value}%` : "-"}`,
        },
        {
          key: "fattutto_transmission.record",
          label: this.getDictionary("records", "fattutto"),
        },
        {
          key: "fattutto_transmission.errors",
          label: this.getDictionary("errors", "fattutto"),
          formatter: (value) => (value != null ? value.length : "-"),
        },
      ],
      items: [],
      transmission_status: {
        "-1": "Errore",
        0: "Creato",
        1: "Pronto per la trasmissione",
        2: "In corso",
        3: "Completato",
        4: "Completato con errori",
        5: "In coda",
        6: "Caricamento dati",
      },
    };
  },
  components: {
    // BaseIcon,
    FattuttoTransmissionsTable,
  },
  methods: {
    canVerb,
    toLocaleDate,
    endSendOrRetry() {
      this.hasUserInteraction = false;
      this.$refs[this.tableRef].fetch(this.current_config);
    },
    sendOrRetry() {
      // this.isLoading = true;
      this.$refs[this.tableRef].isLoading = true;
      console.log("this.table_checked_ids", this.table_checked_ids);
      if (!this.table_checked_ids.length) {
        this.endSendOrRetry();
        return;
      }
      /* Make all the Promises and send them all */
      let promises = [];
      this.table_checked_ids.forEach((el) => {
        promises.push(this.sendOrRetryPromise(el));
      });
      Promise.all(promises)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: "Trasmissioni inviate",
          });
          this.endSendOrRetry();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          // this.isLoading = false;
          this.$refs[this.tableRef].isLoading = false;
        });
      // .finally(() => {
      //   this.isLoading = false;
      // });
    },
    sendOrRetryPromise(item) {
      const payload = {
        date: item.consolidated_at,
      };
      const Repo = RepositoryFactory.get(this.repository);
      const action = this.checkSendOrRetry(item);
      return (
        action === "send"
          ? Repo.send_transmission(payload)
          : Repo.retry_transmission(item.fattutto_transmission.id)
      )
        .then(() => {})
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          // this.isLoading = false;
          this.$refs[this.tableRef].isLoading = false;
        });
    },
    checkSendOrRetry(f) {
      let action;
      if (!Object.prototype.hasOwnProperty.call(
          f.fattutto_transmission,
          'transmission_status'
      )) {
        action = "send";
      } else if (
        Object.keys(f.fattutto_transmission).length &&
        [3, 4].includes(f?.fattutto_transmission?.transmission_status)
      ) {
        action = "retry";
      }
      if (!action) {
        this.$showSnackbar({
          preset: "error",
          text: `Siamo spiacenti, c'è stato un errore. Si prega di ripovare più tardi.`,
        });
        return;
      }
      return action;
    },
    onInteraction(checkedIds) {
      console.log("checkedIds", checkedIds);
      this.table_checked_ids = checkedIds;
      this.hasUserInteraction = checkedIds.length ? true : false;
    },
    saveOnPageChange() {
      this.sendOrRetry();
    },
    onCancelConfirm() {
      // this.$showSnackbar({
      //   preset: "info",
      //   text: `Trasmissione annullata`,
      // });
      // this.hasUserInteraction = false;
      return false;
    },
    // initFilter() {
    //   let init = {
    //     byAttribute: {
    //       setup_at: null,
    //     },
    //     byBankAccount: {
    //       id: null,
    //     },
    //   };
    //   return init;
    // },
    // customFilterName(key, prefix = "byAttribute") {
    //   return `${prefix}.${key}`;
    // },
    // onSearch(name) {
    //   if (
    //     typeof this.filter?.byAttribute?.setup_at !== "undefined" &&
    //     this.filter?.byAttribute?.setup_at !== null
    //   ) {
    //     const setup_at_moment = moment(this.filter.byAttribute.setup_at).format(
    //       "YYYY-MM"
    //     );
    //     this.setupAtMoment = this.filter.byAttribute.setup_at;
    //     this.filter.byAttribute.setup_at = setup_at_moment;
    //   }
    //   let criteria = this.filter;
    //   this.saveFilterByName({ name, criteria });
    //   this.removePaginationByName(name);
    //   this.$refs[this.tableRef].currentPage = 1;
    //   this.$refs[this.tableRef].fetch().then(() => {
    //     if (Object.keys(this.setupAtMoment).length)
    //       this.filter.byAttribute.setup_at = this.setupAtMoment;
    //   });
    // },
    // onClearFilter(name) {
    //   this.resetFilter();
    //   this.resetPagination();
    //   this.removeFilterByName(name);
    //   this.removePaginationByName(name);
    //   this.removeSortByName(name);
    //   this.$refs[this.tableRef].sortBy = "";
    //   this.$refs[this.tableRef].sortDesc = false;
    // },
    // ...mapActions("filters", {
    //   saveFilterByName: "saveByName",
    //   removeFilterByName: "removeByName",
    // }),
    // ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    // ...mapActions("sorts", {
    //   removeSortByName: "removeByName",
    // }),
    // ...mapGetters("auth", {
    //   getBankAccounts: "bank_accounts",
    // }),
    // onSaveFilter(name, criteria) {
    //   this.saveFilterByName({ name, criteria });
    // },
    // onRefreshContent(name) {
    transmissionActionsEnd() {
      this.onRefreshContent();
    },
    onRefreshContent() {
      // let criteria = this.filter;
      // this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].fetch(this.current_config);
    },
  },
  created() {
    // const Repo = RepositoryFactory.get("fattutto");
    // this.isLoading = true;
    // Repo.index_transmission()
    //   .then((response) => {
    //     console.log("response", response);
    //     debugger;
    //     this.items = response?.data?.data ?? [];
    //   })
    //   .catch((error) => {
    //     let errMsg = this.$getErrorMessage(error);
    //     this.$showSnackbar({
    //       preset: "error",
    //       text: `${errMsg}`,
    //     });
    //   })
    //   .finally(() => {
    //     this.isLoading = false;
    //   });
  },
  computed: {
    hasChanges() {
      return this.hasUserInteraction;
    },
    rep() {
      return this.repository;
    },
  },
  mounted() {
    this.current_config.verb = {
      name: "transmissions",
      canVerb: this.canVerb("fattutto", "send"),
    };
    this.$refs[this.tableRef].fetch(this.current_config);
  },
};
</script>
