var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.canVerb('fattutto', 'send'))?_c('div',{staticClass:"fattutto-index-transmissions mx-1"},[_c('b-overlay',{attrs:{"center":"","rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center mt-5"},[_c('b-icon',{attrs:{"icon":"stopwatch","font-scale":"3","animation":"cylon"}}),_vm._v(" "),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Operazione in corso...")])],1)]},proxy:true}],null,false,1492235527)},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"align":"right"}},[_c('b-button',{attrs:{"type":"button","variant":"lisaweb","size":"sm","title":"Refresh"},on:{"click":function($event){return _vm.onRefreshContent()}}},[_c('b-icon',{attrs:{"icon":"arrow-clockwise","aria-hidden":"true"}}),_vm._v("Ricarica")],1)],1)],1),_vm._v(" "),_c('fattutto-transmissions-table',{ref:_vm.tableRef,attrs:{"fields":_vm.fields,"items":_vm.items,"repository":_vm.repository,"resource":_vm.resource,"hasChecks":"","hasChecksPaginated":true,"onPagination":{
        action: 'confirm',
        checkCondition: _vm.hasChanges,
        title: 'Conferma salvataggio',
        message: 'Stai per cambiare pagina. Salvare le modifiche?',
        yesCallback: _vm.saveOnPageChange,
        noCallback: _vm.onCancelConfirm,
        yesLabel: 'SALVA',
        yesVariant: 'lisaweb',
        noLabel: 'ANNULLA',
      }},on:{"interaction":_vm.onInteraction,"singleCheck":_vm.onInteraction,"onSendOrRetry":_vm.sendOrRetry,"transmissionActionsEnd":_vm.transmissionActionsEnd}})],1)],1):_c('div',{staticClass:"mt-3"},[_c('b-icon',{attrs:{"icon":"info-circle","scale":"1.00"}}),_vm._v("\n  Non hai i permessi\n")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }