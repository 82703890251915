<template>
  <div
    class="fattutto-index-transmissions mx-1"
    v-if="canVerb('fattutto', 'config')"
  >
    <b-overlay center rounded="sm">
      <b-row>
        <b-col align="left">
          <b-button-group
            v-b-toggle:collapse-1
            class="my-2 filter-button-group"
            :title="getDefaultFilterMessage()"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Filtra
          </b-button-group>
        </b-col>
        <!--<b-col align="right">
          <b-button
            :id="`popover-create`"
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="onAdd"
            title="Crea"
            v-if="canVerb(resource, 'store')"
            ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
            Crea</b-button
          >
        </b-col> -->
        <!-- <b-col align="right">
          <b-button
            type="button"
            variant="lisaweb"
            size="sm"
            @click="onRefreshContent()"
            title="Refresh"
            ><b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon
            >Ricarica</b-button
          >
        </b-col> -->
      </b-row>

      <b-collapse visible id="collapse-1">
        <b-form @submit.prevent="onSearch(filterName)">
          <b-card class="filter">
            <b-row>
              <div class="col-md-3">
                <base-select
                  name="entity_type"
                  vid="entity_type"
                  label="Entità"
                  :options="[
                    {
                      value: 'variousAccounting',
                      text: 'Causale contabile movimenti vari',
                    },
                    { value: 'insurer', text: 'Compagnia' },
                    { value: 'sector', text: 'Comparto contabile' },
                    { value: 'broker', text: 'Produttore' },
                  ]"
                  v-model="filter.configurable_type"
                />
              </div>
              <div class="col-md-3">
                <base-input
                  name="fattutto_code"
                  vid="fattutto_code"
                  label="Codice Fattutto"
                  placeholder="Inserisci un codice"
                  v-model="filter.code"
                />
              </div>
              <div class="form-group col-md-3 align-self-end">
                <b-button
                  type="submit"
                  variant="lisaweb"
                  size="sm"
                  v-b-toggle:collapse-1
                  >Cerca</b-button
                >
                <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter(filterName)"
                  >Reset</b-button
                >
              </div>
            </b-row>
          </b-card>
        </b-form>
      </b-collapse>

      <fattutto-configuration-table
        :fields="fields"
        :items="items"
        :repository="repository"
        :resource="resource"
        :filterName="filterName"
        :ref="tableRef"
        @forcedFetch="forcedFetch"
      ></fattutto-configuration-table>

      <!-- <div>
        <multi-select-option-modal
          title="Creazione nuovo flusso"
          description=""
          @create="create"
        />
      </div> -->

      <template #overlay>
        <div class="text-center mt-5">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
// import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import FattuttoConfigurationTable from "@/components/tables/FattuttoConfiguration.vue";
import FormMixin from "@/mixins/FormMixin";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions } from "vuex";
import { toLocaleDate } from "@/utils/dates";
import moment from "moment";
moment.locale("it");
import { canVerb } from "@/utils/permissions";

export default {
  mixins: [CustomFiltersMixin, FormMixin],
  data() {
    return {
      isLoading: false,
      repository: "fattutto",
      resource: "config",
      tableRef: "FattuttoConfigTableRef",
      current_config: {},
      filterName: "filterFattuttoConfig",
      filter: this.initFilter(),
      fields: [
        {
          key: "actions",
          label: "Azioni",
          thClass: "align-middle",
          thStyle: {
            width: "20px",
            //width: `${20 * this.actions.length}px`,
          },
          tdClass: "bg-light actions",
        },
        {
          key: "configurable_type",
          label: this.getDictionary("entity", "fattutto"),
          formatter: (value) => this.renderConfigurableTypeLabel(value),
          sortable: true,
          sortKey: "configurable_type",
        },
        {
          key: "configurable.code",
          label: this.getDictionary("entity_code", "fattutto"),
        },
        {
          key: "active",
          label: this.getDictionary("active", "fattutto"),
          formatter: (value) => this.renderActiveLabel(value),
        },
        {
          key: "code",
          label: this.getDictionary("code", "fattutto"),
          formatter: (value) => (value ? value : "-"),
          sortable: true,
          sortKey: "code",
        },
        {
          key: "ledger_type",
          label: this.getDictionary("ledger_type", "fattutto"),
          formatter: (value) => this.renderLedgerTypeLabel(value),
        },
        {
          key: "ledger_code",
          label: this.getDictionary("ledger_code", "fattutto"),
          formatter: (value) => (value != null ? value : "-"),
        },
        {
          key: "negative_code",
          label: this.getDictionary("negative_code", "fattutto"),
          formatter: (value) => (value != null ? value : "-"),
        },
      ],
      items: [],
      transmission_status: {
        "-1": "Errore",
        0: "Creato",
        1: "Pronto per la trasmissione",
        2: "In corso",
        3: "Completato",
        4: "Completato con errori",
        5: "In coda",
        6: "Caricamento dati",
      },
    };
  },
  components: {
    BaseInput,
    BaseSelect,
    FattuttoConfigurationTable,
  },
  methods: {
    canVerb,
    toLocaleDate,
    initFilter() {
      let init = {
        configurable_type: null,
        code: null,
      };
      return init;
    },
    // customFilterName(key, prefix = "byAttribute") {
    //   return `${prefix}.${key}`;
    // },
    onSearch(name) {
      //   if (
      //     typeof this.filter?.byAttribute?.setup_at !== "undefined" &&
      //     this.filter?.byAttribute?.setup_at !== null
      //   ) {
      //     const setup_at_moment = moment(this.filter.byAttribute.setup_at).format(
      //       "YYYY-MM"
      //     );
      //     this.setupAtMoment = this.filter.byAttribute.setup_at;
      //     this.filter.byAttribute.setup_at = setup_at_moment;
      //   }
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch(this.current_config);
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    // onRefreshContent(name) {
    forcedFetch() {
      this.$refs[this.tableRef].fetch(this.current_config);
    },
    renderConfigurableTypeLabel(entity) {
      let ret = "";
      switch (entity) {
        case "App\\Models\\Insurer":
          ret = "Compagnia";
          break;
        case "App\\Models\\Broker":
          ret = "Produttore";
          break;
        case "App\\Models\\VariousAccounting":
          ret = "Causale contabile movimenti vari";
          break;
        case "App\\Models\\Sector":
          ret = "Comparto contabile";
          break;
        default:
          console.log("No entity matched!");
          break;
      }
      return ret;
    },
    renderActiveLabel(status) {
      return status === 1 ? "Sì" : "No";
    },
    renderLedgerTypeLabel(type) {
      let ret = "";
      switch (type) {
        case 0:
          ret = "Compagnia";
          break;
        case 1:
          ret = "Produttore";
          break;
        case 2:
          ret = "Codice";
          break;
        case 3:
          ret = "-";
          break;
        default:
          console.log("type entity matched!");
          break;
      }
      return ret;
    },
    // transmissionActionsEnd() {
    //   this.onRefreshContent();
    // },
    // onRefreshContent() {
    //   // let criteria = this.filter;
    //   // this.saveFilterByName({ name, criteria });
    //   this.$refs[this.tableRef].fetch(this.current_config);
    // },
  },
  created() {
    // const Repo = RepositoryFactory.get("fattutto");
    // this.isLoading = true;
    // Repo.index_transmission()
    //   .then((response) => {
    //     console.log("response", response);
    //     debugger;
    //     this.items = response?.data?.data ?? [];
    //   })
    //   .catch((error) => {
    //     let errMsg = this.$getErrorMessage(error);
    //     this.$showSnackbar({
    //       preset: "error",
    //       text: `${errMsg}`,
    //     });
    //   })
    //   .finally(() => {
    //     this.isLoading = false;
    //   });
  },
  mounted() {
    this.current_config.verb = {
      name: "configuration",
      canVerb: this.canVerb("fattutto", "config"),
    };
    this.$refs[this.tableRef].fetch(this.current_config);
  },
};
</script>
